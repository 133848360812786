import Link from 'next/link';
import { FC } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';

// eslint-disable-next-line import/no-unresolved
import { Except } from 'type-fest';

import GradientCardWithImage, {
  IGradientCardWithImage,
} from 'components/GradientCardWithImage/GradientCardWithImage';
import { IDraw } from 'models/IDraw';

interface IProps extends Except<IGradientCardWithImage, 'children'> {
  draw: IDraw;
}

const StandardDrawGradientCardWithImage: FC<IProps> = ({
  draw,
  style = {},
  ...props
}) => {
  return (
    <GradientCardWithImage
      color={draw.header_background_color}
      imageUrl={draw.draw_icon?.url}
      imageAlt={draw.heading}
      style={{ ...style, minHeight: '220px' }}
      {...props}
    >
      <Row className="justify-content-between">
        <Col xs={12} md={6} lg={8}>
          <h4 className="h1 m-0 text-white text-center text-md-left">
            {draw.heading}
          </h4>
        </Col>

        <Col md="auto" className="flex-grow-0 mt-6 mt-md-0">
          {/* TODO: maybe the buy-now page we can use prefetch true */}
          <Link href="/buy-now" prefetch={false}>
            <Button as="a" variant="danger d-block">
              Buy Tickets
            </Button>
          </Link>

          <Link href={`/lottery/${draw.draw_no}`} prefetch={false}>
            <Button
              as="a"
              variant="link"
              className="d-block text-white mt-0 ml-2 ml-md-0 mt-3 pb-0 mb-n3 pt-0"
            >
              View prize details
              <FaChevronRight
                color="white"
                className="ml-2 mt-n1"
                size="12px"
              />
            </Button>
          </Link>
        </Col>
      </Row>
    </GradientCardWithImage>
  );
};

export default StandardDrawGradientCardWithImage;
