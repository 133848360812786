import { FC, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { RowProps } from 'react-bootstrap/Row';

import cx from 'classnames';

import Card from 'components/Card/Card';
import { IDrawWinner } from 'models/IDrawWinner';

import s from './WinnerStoriesRow.module.scss';

interface IWinnerTestimonialRowProps extends RowProps {
  drawWinner: IDrawWinner;
}

const WinnerTestimonialRow: FC<IWinnerTestimonialRowProps> = ({
  drawWinner,
  className,
  ...props
}) => {
  const [modalOpened, setModalOpened] = useState(false);
  const toggleModalOpened = () => {
    setModalOpened(!modalOpened);
  };
  return (
    <>
      <Row
        className={cx(className, 'flex-column-reverse flex-lg-row')}
        {...props}
      >
        <Col
          lg={6}
          className={cx('pb-lg-4 pb-0 d-flex position-relative', s.textColumn)}
        >
          <Card className="overflow-hidden mr-0 mr-lg-n3 bg-secondary mx-md-n-container rounded-md-none mt-n6 pt-5 pt-lg-0 mt-lg-0 flex-grow-1 no-shadow">
            <Card.Body className="d-flex flex-column justify-content-center p-6 pr-5 pr-lg-6">
              <h2 className="heading heading-center heading-danger text-center text-black">
                {drawWinner.winners_quote}
              </h2>
              <p className="text-center">{drawWinner.expert}</p>
              {drawWinner.video && (
                <Row className="justify-content-center">
                  <Col lg="auto" className="d-flex justify-content-center">
                    <Button
                      onClick={() => {
                        toggleModalOpened();
                      }}
                      as="a"
                      variant="link"
                      className="text-black font-weight-bold text-decoration-underline"
                    >
                      Watch {drawWinner.winner_first_name}’s story
                    </Button>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
        {drawWinner.winner_image?.url && (
          <Col lg={6} className="d-flex flex-column pt-lg-4 pt-0">
            <Card
              className={cx(
                s.cardImage,
                'bg-secondary flex-grow-1 ml-0 ml-lg-n5 overflow-hidden',
              )}
            >
              <img
                loading="lazy"
                className="position-absolute w-100 h-100 d-block object-fit-cover"
                src={drawWinner.winner_image.url}
                alt={drawWinner?.winner_first_name}
              />
            </Card>
          </Col>
        )}
      </Row>
      {drawWinner?.video && (
        <Modal show={modalOpened} onHide={toggleModalOpened} size="xl" centered>
          <iframe
            title={`${drawWinner.winner_first_name}'s Story`}
            width="100%"
            style={{ height: '50vh' }}
            src={drawWinner.video}
            frameBorder="0"
          />
          {drawWinner.video_transcript && (
            <Card className="mt-3">
              <Card.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: drawWinner.video_transcript,
                  }}
                />
              </Card.Body>
            </Card>
          )}
        </Modal>
      )}
    </>
  );
};

export default WinnerTestimonialRow;
