import Link from 'next/link';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import GradientCard from 'components/GradientCard/GradientCard';
import StandardDrawGradientCardWithImage from 'components/StandardDrawGradientCardWithImage/StandardDrawGradientCardWithImage';
import { IDraw } from 'models/IDraw';
import { IPersona } from 'models/IPersona';

import s from './HomeDrawSlider.module.scss';

interface IProps {
  draws: IDraw[];
  persona?: IPersona;
}

const SLIDER_SETTING = {
  dots: true,
  infinite: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  speed: 500,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const HomeDrawSlider = ({ draws, persona }: IProps) => {
  return (
    <DynamicSlider className={s.homeDrawSlider} {...SLIDER_SETTING}>
      {draws.map(draw => (
        <div key={draw.id}>
          {!!draw.draw_hero_images?.[0]?.image?.url && (
            <div className="position-relative flex-grow-1">
              <img
                src={draw.draw_hero_images[0].image.url}
                alt={draw.heading}
                className="object-fit-cover w-100 h-100 position-absolute top-0 left-0 d-block"
              />
            </div>
          )}

          <Container className="mt-n6 flex-grow-0 flex-shrink-0">
            <StandardDrawGradientCardWithImage draw={draw} />
          </Container>
        </div>
      ))}
      {persona?.cc_page_sub_hero_image_asset?.media_file?.url && (
        <div key={persona.id}>
          <div className="position-relative flex-grow-1">
            <img
              src={persona.cc_page_sub_hero_image_asset?.media_file.url}
              alt={persona.cc_page_sub_hero_image_asset?.media_file.name}
              className="object-fit-cover w-100 h-100 position-absolute top-0 left-0 d-block"
            />
          </div>
          <Container className="mt-n6 flex-grow-0 flex-shrink-0">
            <GradientCard>
              <Card.Body className="p-6 d-flex">
                <Row className="justify-content-between">
                  <Col xs={12} md={6} lg={8}>
                    <h4 className="h1 m-0 text-white text-center text-md-left">
                      Sign up to the Champions club and unlock over $1.25
                      Million in exclusive prizes
                    </h4>
                  </Col>

                  <Col
                    xs={12}
                    md={5}
                    lg={4}
                    xl={3}
                    className="flex-grow-0 mt-6 mt-md-0"
                  >
                    <Link href="/subscribe" prefetch={false}>
                      <Button as="a" variant="danger d-block">
                        Subscribe Today
                      </Button>
                    </Link>

                    <img
                      loading="lazy"
                      src="/imgs/championsClubBadge-negative.svg"
                      alt="Champions Club Badge"
                      className="d-block mt-4 px-3 mx-auto"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </GradientCard>
          </Container>
        </div>
      )}
    </DynamicSlider>
  );
};

export default HomeDrawSlider;
