import { FC } from 'react';
import {
  Card as ReactBootstrapCard,
  CardProps as DefaultReactCardProps,
} from 'react-bootstrap';

import cx from 'classnames';

import LoadingIcon from 'components/Icons/Loading/Loading';

interface CardHeaderProps {
  className?: string;
  displaySeparator?: boolean;
}

const CardHeader: FC<CardHeaderProps> = ({
  displaySeparator = true,
  children,
  className,
  ...props
}) => {
  return (
    <ReactBootstrapCard.Header className={cx('pb-0', className)} {...props}>
      <div className={cx({ 'border-bottom pb-2': displaySeparator }, 'w-100')}>
        {children}
      </div>
    </ReactBootstrapCard.Header>
  );
};

interface CardVideoProps {
  className?: string;
  videoURL?: string;
}

const CardVideo: FC<CardVideoProps> = ({ videoURL, className, ...props }) => {
  return (
    <div className={cx('w-100', className)} {...props}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video className="w-100" src={videoURL} preload="auto" controls />
    </div>
  );
};

interface ReactCardProps {
  Body: typeof ReactBootstrapCard.Body;
  Header: typeof CardHeader;
  Video: typeof CardVideo;
  Img: typeof ReactBootstrapCard.Img;
  ImgOverlay: typeof ReactBootstrapCard.ImgOverlay;
}

export interface CardProps extends DefaultReactCardProps {
  className?: string;
  loading?: boolean;
  style?: any;
}

const Card: FC<CardProps> & ReactCardProps = ({
  className,
  loading,
  children,
  ...props
}) => {
  return (
    <ReactBootstrapCard
      className={cx(
        'position-relative',
        { 'overflow-hidden': loading },
        className,
      )}
      {...props}
    >
      {loading && (
        <div className="left-0 top-0 position-absolute bg-white-70 d-flex justify-content-center align-items-center w-100 h-100 z-1000">
          <LoadingIcon height="4rem" width="4rem" />
        </div>
      )}
      {children}
    </ReactBootstrapCard>
  );
};

Card.Body = ReactBootstrapCard.Body;
Card.Header = CardHeader;
Card.Video = CardVideo;
Card.Img = ReactBootstrapCard.Img;
Card.ImgOverlay = ReactBootstrapCard.ImgOverlay;

export default Card;
