import { forwardRef, HTMLAttributes, ReactNode } from 'react';
import { Container, ContainerProps } from 'react-bootstrap';

import cx from 'classnames';

export interface SectionWithContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  containerProps?: ContainerProps;
  noPadding?: boolean;
  children?: ReactNode;
}

const SectionWithContainer = forwardRef<
  HTMLDivElement | undefined,
  SectionWithContainerProps
>(({ className, containerProps, noPadding, children, ...props }, ref) => {
  let additionalProps = {};
  if (ref !== undefined) {
    additionalProps = { ref };
  }
  return (
    <section
      className={cx(!noPadding && 'py-4 py-sm-5 py-lg-6', 'w-100', className)}
      {...props}
      {...additionalProps}
    >
      <Container {...containerProps}>{children}</Container>
    </section>
  );
});
SectionWithContainer.displayName = 'SectionWithContainer';

export default SectionWithContainer;
