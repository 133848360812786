import { FC, useState } from 'react';

// eslint-disable-next-line import/no-unresolved
import { Except } from 'type-fest';

import DrawCard, { IDrawCardProps } from 'components/DrawCard/DrawCard';
import { useCart } from 'contexts/cartContext';
import { ITicketBook } from 'models/ITicketBook';

export interface IDrawCardContainer
  extends Except<
    IDrawCardProps,
    'quantityValue' | 'onAddToCart' | 'onTicketBookChange'
  > {
  initialTicketBook?: ITicketBook;
}

const DrawCardContainer: FC<IDrawCardContainer> = ({
  initialTicketBook,
  draw,
  ...props
}) => {
  const {
    actions: { addTicketBookToCart },
  } = useCart();
  const [ticketBook, setTicketBook] = useState<ITicketBook | undefined>(
    initialTicketBook ||
      (draw.ticket_books || []).find(
        ({ is_default_selection_ind }) => is_default_selection_ind,
      ) ||
      draw.ticket_books?.[0],
  );
  return (
    <DrawCard
      {...props}
      draw={draw}
      quantityValue={1}
      ticketBookValue={ticketBook}
      onTicketBookChange={value => {
        setTicketBook(value);
      }}
      onAddToCart={() => {
        if (ticketBook) {
          addTicketBookToCart(ticketBook, 1);
        }
      }}
    />
  );
};

export default DrawCardContainer;
