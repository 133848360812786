import Link from 'next/link';
import { FC, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import SectionWithContainer, {
  SectionWithContainerProps,
} from 'components/SectionWithContainer/SectionWithContainer';
import Select from 'components/Select/Select';
import WinnerTestimonialRow from 'components/WinnerStoriesSection/WinnerStoriesRow';
import { IDrawWinner } from 'models/IDrawWinner';
import { IPersona } from 'models/IPersona';

interface IWinnerStoriesSectionProps extends SectionWithContainerProps {
  persona?: IPersona;
  drawWinners: IDrawWinner[];
}

const WinnerStoriesSection: FC<IWinnerStoriesSectionProps> = ({
  persona,
  drawWinners,
  ...props
}) => {
  const [selectedDrawWinner, setSelectedDrawWinner] = useState<IDrawWinner>(
    drawWinners[0],
  );

  return (
    <>
      <SectionWithContainer {...props} containerProps={{ fluid: 'lg' }}>
        <RowWithOffsetCol className="mb-7">
          <h2 className="heading heading-danger px-0 mb-7 col-12 col-md-6">
            {persona?.winners_heading}
          </h2>
          <p className="mb-7 large-body">{persona?.winners_content}</p>
          <Row>
            <Col md={9} lg={7}>
              <Row className="align-items-center">
                <Col md="auto" className="mb-3">
                  <h3>Choose Lottery</h3>
                </Col>
                <Col className="mb-3">
                  <Select
                    rounded
                    controlStyles={{
                      borderColor: 'var(--primary)',
                    }}
                    dropDownIndicatorStyles={{
                      color: 'var(--primary)',
                    }}
                    singleValueStyles={{
                      color: 'var(--primary)',
                    }}
                    getOptionLabel={(value: any) =>
                      `Lottery ${value.draw?.draw_no} winners`
                    }
                    onChange={(value: any) => {
                      setSelectedDrawWinner(value);
                    }}
                    isClearable={false}
                    options={drawWinners}
                    value={selectedDrawWinner}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </RowWithOffsetCol>
        {selectedDrawWinner && (
          <WinnerTestimonialRow
            noGutters
            className="mb-4"
            drawWinner={selectedDrawWinner}
          />
        )}
        {selectedDrawWinner.draw && (
          <Row className="mt-4 mt-md-7 text-center">
            <Col md={12} className="mb-4 mb-md-0">
              <Link
                href={`/lottery/${selectedDrawWinner.draw?.draw_no}`}
                passHref
                prefetch={false}
              >
                <Button
                  as="a"
                  variant="link"
                  className="px-0 px-md-5 text-decoration-underline text-black font-weight-bold"
                >
                  View full lottery results
                </Button>
              </Link>
            </Col>
          </Row>
        )}
      </SectionWithContainer>
    </>
  );
};

export default WinnerStoriesSection;
