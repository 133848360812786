import Link from 'next/link';
import { FC, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';

import currencyFormat from 'utils/curencyFormat';

import Card, { CardProps } from 'components/Card/Card';
import Select from 'components/Select/Select';
import { IDraw } from 'models/IDraw';
import { IPersona } from 'models/IPersona';

interface IProps extends CardProps {
  persona: IPersona;
  draws: IDraw[];
}

const WelcomePersonaDrawsCard: FC<IProps> = ({ persona, draws, ...props }) => {
  const [selectedDraw, setSelectedDraw] = useState<IDraw | undefined>(draws[0]);

  return (
    <Card {...props}>
      <Card.Body className="p-md-6 p-4">
        <Row className="mb-n5">
          <Col lg={5} className="mb-5 pr-lg-4">
            <h2 className="heading heading-danger mb-6 mb-lg-7">
              {persona.welcome_heading}
            </h2>
            <h3 className="mb-4">{persona.welcome_sub_heading}</h3>
            {persona.welcome_content && (
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: persona.welcome_content }}
              />
            )}
          </Col>
          <Col lg={7} className="mb-5">
            <Row className="mb-md-4">
              <Col className="col d-none d-md-block" md={3} lg={3}>
                {selectedDraw?.thumbnail?.url && (
                  <Card className="overflow-hidden">
                    <img
                      loading="lazy"
                      className="object-fit-contain d-block w-100"
                      src={selectedDraw.thumbnail.url}
                      alt={selectedDraw.heading}
                    />
                  </Card>
                )}
              </Col>
              <Col lg={9}>
                <strong className="mb-3 d-block">Open Lotteries</strong>
                <Select
                  rounded
                  controlStyles={{
                    borderColor: 'var(--primary)',
                  }}
                  dropDownIndicatorStyles={{
                    color: 'var(--primary)',
                  }}
                  singleValueStyles={{
                    color: 'var(--primary)',
                  }}
                  name="quantity"
                  className="col-12 col-lg-6 px-0 mb-3 text-primary"
                  options={draws || []}
                  labelAttribute="draw_no"
                  getOptionLabel={(value: any) => `Lottery ${value.draw_no}`}
                  isClearable={false}
                  value={selectedDraw}
                  onChange={draw => {
                    setSelectedDraw(draw as IDraw);
                  }}
                />
                <h5 className="large-body mt-5 mb-5">
                  {selectedDraw?.heading}
                </h5>
                <ul className="list-style-type-none pl-0 m-0">
                  <li>
                    <strong>Total First Prize:</strong>{' '}
                    {currencyFormat(selectedDraw?.total_first_prize_value)}
                  </li>
                  <li>
                    <strong>First Prize:</strong> {selectedDraw?.first_prize}
                  </li>
                  {selectedDraw?.bonus_prize && (
                    <li>
                      <strong>Bonus Prize:</strong> {selectedDraw.bonus_prize}
                    </li>
                  )}
                </ul>

                <Row className="mt-5">
                  <Col md="auto" className="pl-md-2">
                    <Link href="/buy-now" passHref prefetch={false}>
                      <Button as="a" variant="danger" className="btn-block">
                        Buy Tickets
                      </Button>
                    </Link>
                  </Col>

                  <Col md="auto" className="pl-md-0 mt-2 mt-md-0">
                    <Link
                      href={`/lottery/${selectedDraw?.draw_no}`}
                      passHref
                      prefetch={false}
                    >
                      <Button
                        as="a"
                        variant="link"
                        className="text-primary font-weight-bold px-0 btn-block"
                      >
                        View prize details
                        <FaChevronRight size="12px" className="ml-1" />
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default WelcomePersonaDrawsCard;
