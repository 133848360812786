import { GetServerSideProps } from 'next';
import ErrorPage from 'next/error';
import Head from 'next/head';
import { Col, Container, Row } from 'react-bootstrap';

import { captureException } from '@sentry/nextjs';
import DrawCardContainer from 'containers/DrawCardContainer/DrawCardContainer';
import { getAllowPreviewFromCookie } from 'utils/permissions';
import requestValuesForMainLayout from 'utils/requestValuesForMainLayout';

import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import HomeDrawSlider from 'components/HomeDrawSlider/HomeDrawSlider';
import LifeStageCard from 'components/LifeStageCard/LifeStageCard';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import SectionWithContainer from 'components/SectionWithContainer/SectionWithContainer';
import SupportiveMessageRow from 'components/SupportiveMessageRow/SupportiveMessageRow';
import WelcomePersonaDrawsCard from 'components/WelcomePersonaDrawsCard/WelcomePersonaDrawsCard';
import WinnerStoriesSection from 'components/WinnerStoriesSection/WinnerStoriesSection';
import { useApp } from 'contexts/appContext';
import { IChampionClubBenefit } from 'models/IChampionClubBenefit';
import { ICommonMainLayoutPropsForPages } from 'models/ICommonMainLayoutPropsForPages';
import { DrawSteps, IDraw } from 'models/IDraw';
import { IDrawWinner } from 'models/IDrawWinner';
import { ILifeStage } from 'models/ILifeStage';
import { Personas } from 'models/IPersona';
import { IPersonaSupportiveMessage } from 'models/IPersonaSupportiveMessage';
import { ITicketBook } from 'models/ITicketBook';
import { ChampionClubBenefitService } from 'services/ChampionClubBenefitService';
import { DrawService } from 'services/DrawService';
import { DrawWinnerService } from 'services/DrawWinnerService';
import { LifeStageService } from 'services/LifeStageService';
import { PersonaSupportiveMessageService } from 'services/PersonaSupportiveMessageService';
import { TicketBookService } from 'services/TicketBookService';

import MainLayout from '../layouts/MainLayout';
import renderCommonMetaTags from '../utils/renderCommonMetaTags';

const SLIDER_SETTING = {
  dots: true,
  infinite: true,
  speed: 500,
  centerPadding: '20px',
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

interface IProps extends ICommonMainLayoutPropsForPages {
  statusCode?: number;
  host: string;
  draws?: IDraw[];
  drawWinners?: IDrawWinner[];
  supportiveMessage?: IPersonaSupportiveMessage;
  championClubBenefits?: IChampionClubBenefit[];
  lifeStage?: ILifeStage;
  lastWinnerTicketBooks?: ITicketBook[];
  commonPropsForMainLayout: ICommonMainLayoutPropsForPages;
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  try {
    const allowToPreview = await getAllowPreviewFromCookie(req.headers.cookie);

    const drawService = new DrawService();
    const personaSupportiveMessageService = new PersonaSupportiveMessageService();
    const championClubBenefitService = new ChampionClubBenefitService();
    const drawWinnersService = new DrawWinnerService();
    const lifeStageService = new LifeStageService();
    const ticketBookService = new TicketBookService();
    const [
      { data: draws },
      supportiveMessage,
      { data: championClubBenefits },
      lifeStage,
      { data: promotionalTicketBooks },
      { data: lastWinnerTicketBooks },
      { data: drawWinners },
      commonPropsForMainLayout,
    ] = await Promise.all([
      drawService.getDrawsForHomePage([DrawSteps.open]),
      personaSupportiveMessageService.getSupportiveMessageByPersonaAndPage(
        Personas.Unknown,
        null,
      ),
      championClubBenefitService.getChampionClubBenefits(),
      lifeStageService.getLifeStageByName(Personas.Unknown),
      ticketBookService.getTicketBooksByDrawType([DrawSteps.open], {
        promotional_book_ind: true,
      }),
      ticketBookService.getTicketBooksByDrawType([DrawSteps.open], {
        last_winning_ind: true,
      }),
      drawWinnersService.getDrawWinners(),
      requestValuesForMainLayout(allowToPreview),
    ]);

    return {
      props: {
        draws,
        drawWinners,
        supportiveMessage,
        championClubBenefits,
        lifeStage,
        promotionalTicketBooks,
        lastWinnerTicketBooks,
        commonPropsForMainLayout,
      },
    };
  } catch (error: any) {
    captureException(error);
    console.error('[ERROR]', error);
    return {
      props: {
        statusCode: error.status || null,
      },
    };
  }
};

const HomePage = ({
  statusCode = 200,
  draws = [],
  drawWinners = [],
  lastWinnerTicketBooks = [],
  host = '',
  supportiveMessage,
  championClubBenefits = [],
  lifeStage,
  commonPropsForMainLayout,
}: IProps) => {
  const {
    state: { persona },
  } = useApp();

  if (statusCode !== 200) {
    return <ErrorPage statusCode={statusCode} />;
  }

  return (
    <>
      <Head>
        {renderCommonMetaTags(
          'Surf Life Saving Lotteries - Shop Tickets Online',
          "Surf Life Saving Exists To Keep Our Beaches Safe And Save Lives - Help Fund This Movement. Don't Miss Out On The Chance To Win One Of Our Incredible Prizes. Buy A Ticket Now. Help Fund Our Volunteers. Win Luxurious Prizes. Win Apartments. Win Cars",
          undefined,
          `${host}/`,
          undefined,
          'surf-life-saving-lottery',
          undefined,
        )}
      </Head>

      <MainLayout
        championClubBenefits={championClubBenefits}
        {...commonPropsForMainLayout}
      >
        <section className="bg-light-grey">
          <HomeDrawSlider draws={draws} persona={persona} />
        </section>

        {persona && (
          <SectionWithContainer className="bg-light-grey">
            <WelcomePersonaDrawsCard
              className="mx-xs-n-container rounded-xs-none"
              persona={persona}
              draws={draws}
            />
          </SectionWithContainer>
        )}

        {lifeStage && (
          <section className="py-4 py-sm-5 py-lg-6">
            <Container>
              <RowWithOffsetCol className="mb-md-7 mb-6">
                <Row>
                  <Col lg={7}>
                    <h2 className="heading heading-danger">
                      {lifeStage?.life_stage_heading}
                    </h2>
                  </Col>
                </Row>
              </RowWithOffsetCol>
            </Container>

            <Container fluid="lg">
              <Row className="mb-n4 d-none d-lg-flex justify-content-center">
                {(lifeStage.life_stage_cards || []).map(lifeStageCard => (
                  <Col
                    key={lifeStageCard.id}
                    md={4}
                    className="d-flex flex-column"
                  >
                    <LifeStageCard
                      className="bg-secondary rounded-xs-none mx-xs-n-container mb-4"
                      lifeStageCard={lifeStageCard}
                    />
                  </Col>
                ))}
              </Row>

              <DynamicSlider
                className="d-block d-lg-none mx-md-n-container"
                centerMode
                {...SLIDER_SETTING}
                arrows={false}
                dots={false}
              >
                {(lifeStage.life_stage_cards || []).map(lifeStageCard => (
                  <div key={lifeStageCard.id} className="px-2 d-flex">
                    <LifeStageCard
                      className="bg-secondary shadow-none flex-grow-1"
                      lifeStageCard={lifeStageCard}
                    />
                  </div>
                ))}
              </DynamicSlider>
            </Container>
          </section>
        )}

        <section className="py-4 py-sm-5 py-lg-6">
          <Container>
            <RowWithOffsetCol className="mb-md-7 mb-6">
              <Row>
                <Col lg={7}>
                  <h2 className="heading heading-danger">
                    Try your luck with the last winning ticket book!
                  </h2>
                  <p className="large-body">{persona?.promotion_content}</p>
                </Col>
              </Row>
            </RowWithOffsetCol>
          </Container>

          <SectionWithContainer noPadding className="pt-3 pb-6">
            {!!lastWinnerTicketBooks.length && (
              <div className="mb-n4">
                {lastWinnerTicketBooks
                  .filter(lastWinnerTicketBook => lastWinnerTicketBook.draw)
                  .map(lastWinnerTicketBook => (
                    <DrawCardContainer
                      imgColumnClassName="rounded-xs-none"
                      className="mb-4 rounded-xs-none mx-xs-n-container"
                      key={lastWinnerTicketBook.id}
                      draw={{
                        ...(lastWinnerTicketBook.draw as IDraw),
                        ticket_books: [lastWinnerTicketBook],
                      }}
                    />
                  ))}
              </div>
            )}
          </SectionWithContainer>
        </section>

        {supportiveMessage && (
          <SectionWithContainer
            className="pt-6 pb-0 pb-md-6"
            containerProps={{ fluid: 'lg' }}
            noPadding
          >
            <SupportiveMessageRow
              className="mx-md-n-container"
              supportiveMessage={supportiveMessage}
            />
          </SectionWithContainer>
        )}

        {persona && (drawWinners || []).length && (
          <WinnerStoriesSection persona={persona} drawWinners={drawWinners} />
        )}
      </MainLayout>
    </>
  );
};

export default HomePage;
